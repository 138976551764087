<template>
  <div>
    仪表盘
    <CommonWangeditor :wangeDitorConfig="wangeDitorConfig"
    ></CommonWangeditor>
  </div>
</template>

<script>
import CommonWangeditor from 'components/CommonWangeditor.vue'
export default {
  data(){
    return{
    wangeDitorConfig:{       
        height: 600,
        focus: true,
        placeholder: '请输入...'
      }
    }
  },
  components: {
    CommonWangeditor
  }
}
</script>

<style lang="scss" scoped>
</style>
